import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { ModalConductor } from '@springforcreators/propel-ui';
import CartConfirmation from 'pages/ListingPage/CartConfirmation';
import UnavailableItemsModal from 'components/UnavailableItemsModal';
import QuickAddToCartModal from 'components/ProductTile/ProductOptions/QuickAddToCartModal';
import { bpProps } from 'utils/responsiveUtils';
import stripePromise from 'utils/stripeUtils';
import { getCartSize } from 'utils/cartUtils';
import './HeaderCart.scss';
import QuickAddOutOfStockModal from '../../ProductTile/ProductOptions/QuickAddOutOfStockModal';

export const HeaderCart = () => {
  const { activeModal } = useSelector(state => state);
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const dispatch = useDispatch();
  const { userCart } = useSelector(state => state);
  const { userCartItem } = useSelector(state => state?.activeModal?.mProps);
  const cartSize = getCartSize(userCart) || 0;
  const [preventBackgroundScroll, setPreventBackgroundScroll] = useState(true);

  useEffect(() => {
    setPreventBackgroundScroll(activeModal.id === 'cart-confirmation');
  }, [activeModal.id]);

  let cartCount = cartSize;
  if (cartSize > 99 || cartSize <= 0) {
    cartCount = cartSize > 99 ? '99+' : '';
  }

  return (
    <div className="header__cart">
      <Link to="/cart">
        <div className="header__cart_button pr_btn pr_btn--md label-s">
          <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_16_1483)">
              <path d="M1.15662 1.00056C1.15662 1.42263 1.5762 1.77849 1.98586 1.76111H2.76793C3.1031 1.76111 3.262 1.8447 3.43 2.01353C3.59717 2.18235 3.74613 2.47367 3.85786 2.79229L7.02586 12.8508C6.52426 13.0872 6.10302 13.4656 5.81427 13.939C5.34917 14.7178 5.134 15.7126 5.134 16.848C5.134 17.2701 5.50641 17.6458 5.926 17.6458H20.0951C20.5147 17.6541 20.8962 17.2701 20.8962 16.848C20.8962 16.4259 20.5147 16.0502 20.0951 16.0593H6.79248C6.86696 15.5247 6.98779 15.0555 7.16489 14.7641C7.40737 14.3611 7.63082 14.2113 7.97593 14.2014H19.3139C20.8325 14.2014 22.2667 13.2348 22.6962 11.7807L24.7916 4.63118C24.9315 4.15284 24.5219 3.60829 24.0369 3.60829H5.77786L5.36737 2.31394C5.35827 2.30401 5.35827 2.29491 5.35827 2.29491C5.2002 1.82567 4.96682 1.31918 4.54724 0.896286C4.07678 0.42906 3.4409 0.16644 2.77786 0.165527H1.98586C1.53896 0.165527 1.15662 0.57932 1.15662 1.00139V1.00056ZM6.27937 5.19477H22.9743L21.1867 11.3214C20.9996 11.9595 20.1059 12.6166 19.3139 12.6166H8.60986L6.27937 5.19477ZM6.4482 18.7059C5.72158 18.7059 5.13482 19.2968 5.13482 20.0293C5.13482 20.7608 5.72158 21.3526 6.4482 21.3526C7.17482 21.3526 7.76158 20.7608 7.76158 20.0284C7.76245 19.8553 7.72914 19.6837 7.66356 19.5234C7.59798 19.3632 7.50141 19.2174 7.3794 19.0946C7.25739 18.9717 7.11233 18.8741 6.95253 18.8074C6.79274 18.7408 6.62135 18.7063 6.4482 18.7059ZM19.5737 18.7059C19.4006 18.7064 19.2292 18.741 19.0695 18.8078C18.9097 18.8746 18.7647 18.9722 18.6428 19.0951C18.5208 19.2181 18.4244 19.3639 18.3589 19.5242C18.2934 19.6845 18.2602 19.8561 18.2612 20.0293C18.2612 20.7608 18.8479 21.3526 19.5745 21.3526C20.3012 21.3526 20.8879 20.7608 20.8879 20.0284C20.8879 19.2968 20.2995 18.7059 19.5737 18.7059Z" fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_16_1483">
                <rect width="24" height="21.5172" fill="white" transform="translate(0.987793)" />
              </clipPath>
            </defs>
          </svg>
          {cartSize >= 1 && <span className="header__cart__count">{cartCount}</span>}
        </div>
      </Link>

      <Elements stripe={ stripePromise }>
        <ModalConductor
          activeModal={ activeModal }
          preventBackgroundScroll={ preventBackgroundScroll }
          setActiveModal={ (state, props) => {
            const updatedProps = activeModal.id === 'cart-confirmation' ? { ...props, userCartItem } : props;
            dispatch(setActiveModal(state, updatedProps));
          } }
          transitionOrigin={ activeModal.id === 'cart-confirmation' && bpIsLT('mobileLg') ? 'bottom' : 'top' }
          modals={ [
            {
              id: 'cart-confirmation',
              className: 'cartconfirm',
              header: 'Added to cart',
              hideCloseBtn: true,
              node: <CartConfirmation />
            },
            {
              id: 'quick-add-confirmation',
              className: 'quick__add__modal',
              node: <QuickAddToCartModal />
            },
            {
              id: 'quick-add-out-of-stock',
              className: 'quick__add__modal__oos',
              node: <QuickAddOutOfStockModal />
            }
          ] }
        />
        <ModalConductor
          activeModal={ activeModal }
          setActiveModal={ (state, props) => {
            dispatch(setActiveModal(state, props));
          } }
          modals={ [
            {
              id: 'cart-unavailable-items',
              className: 'unavailable-items',
              node: <UnavailableItemsModal />
            }
          ] }
        />
      </Elements>
    </div>
  );
};

export default HeaderCart;
